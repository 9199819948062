import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'app-title',
  imports: [NgClass],
  templateUrl: './title.component.html',
  styleUrl: './title.component.scss',
})
export class TitleComponent {
  title = input<string>('');
  titleContainer = input<string>('container pt-3');
  subtitle = input<string>('');
  modal = input<boolean>(false);
  description = input<string>('');
}
