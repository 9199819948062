import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
import { User } from '@admin/models/User.model';
import { plainToClass } from 'class-transformer';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private loggedInSubject = new BehaviorSubject<boolean>(false);
  private profileSubject = new BehaviorSubject<boolean>(false);

  loggedIn$: Observable<boolean> = this.loggedInSubject.asObservable();
  profile$: Observable<boolean> = this.profileSubject.asObservable();

  _user: User | null = null;

  constructor(private storage: LocalStorageService, private router: Router) {}

  get profile(): User | null {
    return this._user;
  }

  set profile(value: any) {
    this._user = value ? plainToClass(User, value) : null;

    if (this._user) {
      this.profileSubject.next(true);
    }
  }

  get logged(): boolean {
    const token = this.getToken();

    return token && token.length > 0 ? true : false;
  }

  // TOKEN KEY
  getToken(): string {
    return this.storage.get<string>('token') ?? '';
  }

  setToken(token: string) {
    this.storage.set('token', token);
    this.loggedInSubject.next(true);
  }

  clearSession() {
    this.setToken('');
    this.loggedInSubject.next(false);
    this.storage.cleanStorage();
    this.router.navigate(['/']);
  }
}
