import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-main-modal',
    imports: [NgClass],
    templateUrl: './main-modal.component.html',
    styleUrls: ['./main-modal.component.scss']
})
export class MainModalComponent {
  @Input() title: string = '';
  @Input() headerClass: string = 'bg-primary text-white';
  @Output() close: EventEmitter<void> = new EventEmitter();

  onClose() {
    this.close.emit();
  }
}
