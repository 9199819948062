import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { MainModalComponent } from '../main-modal/main-modal.component';

@Component({
    selector: 'app-confirm-modal',
    imports: [NgClass, MainModalComponent],
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
  @Input() cancelBtn: string = 'Cancel';
  @Input() cancelClass: string = 'btn-light w-100';
  @Input() hasCancel: boolean = true;
  @Input() confirmBtn: string = 'Accept';
  @Input() confirmClass: string = 'btn-danger w-100';
  @Input() message: string = '';
  @Input() title: string = '';

  constructor(private activeModal: NgbActiveModal) {}

  confirm() {
    this.activeModal.close(true);
  }

  close() {
    this.activeModal.close(false);
  }
}
