import { Component, EventEmitter, Input, Output, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { fadeInAnimation } from '@shared/animations/fade.animation';

@Component({
    selector: 'app-main-card',
    imports: [NgClass, MatIconModule],
    templateUrl: './main-card.component.html',
    styleUrl: './main-card.component.scss',
    animations: [fadeInAnimation]
})
export class MainCardComponent {
  @Input() icon: string = '';
  @Input() image: string = '';
  @Input() hasImage: boolean = true;
  @Input() primary: string = 'arrow_forward_ios';
  @Input() secondary: string = 'edit';
  @Input() extra: string = '';
  onPrimary = output<void>();
  onSecondary = output<void>();
  onExtra = output<void>();

  ngOnInit(): void {
    if (this.hasImage && !this.image && !this.icon) {
      this.image = '/assets/images/interticket.png';
    }
  }
}
