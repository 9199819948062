<div
  class="{{ titleContainer() }}"
  [ngClass]="{ 'modal-header bg-primary text-white': modal() }"
>
  <div class="row">
    <div class="col-12">
      <h3 class="fw-bolder text-uppercase mb-0">{{ title() }}</h3>
    </div>

    @if(subtitle()) {
    <div class="col-12">
      <h5 class="fw-bolder text-uppercase mt-2">{{ subtitle() }}</h5>
    </div>
    } @if (description()) {
    <div class="col-12">
      <p class="mt-2 mb-0">
        {{ description() }}
      </p>
    </div>
    }

    <div class="col-12">
      <ng-content></ng-content>
    </div>
  </div>
</div>
