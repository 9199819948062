import { Component, Input, ViewChild } from '@angular/core';
import { NgbAlert, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export class FormAlert {
  type: string = 'success';
  message: string = '';
}

@Component({
    selector: 'app-alert',
    imports: [NgbAlertModule],
    templateUrl: './alert.component.html',
    styleUrl: './alert.component.scss'
})
export class AlertComponent {
  alertMessage = '';
  _content: FormAlert = new FormAlert();
  private _message$ = new Subject<string>();

  @Input() set content(alertContent: FormAlert | null) {
    if (alertContent) {
      this._content = alertContent;
      this.changeMessage();
    }
  }

  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;

  constructor() {
    this._message$
      .pipe(
        takeUntilDestroyed(),
        tap((message) => (this.alertMessage = message)),
        debounceTime(5000)
      )
      .subscribe(() => this.selfClosingAlert?.close());
  }

  public changeMessage() {
    this._message$.next(`${this._content.message}`);
  }
}
